import { storeLinksQuery } from '../../queries';
import { QueryHookResponse, StoreLinks } from '../../types/internal';
import ServiceConfigs from '../../config';
import { useQuery } from '../../utils';

type UseLinksType = ({ initialData }?: { initialData?: StoreLinks }) => QueryHookResponse<StoreLinks>;

const useLinks: UseLinksType = ({ initialData } = {}) =>
  useQuery<StoreLinks>({
    query: storeLinksQuery,
    variables: { subdomain: ServiceConfigs.getSubDomain() },
    handler: (res: any) => LinksHandler(res.store),
    config: { initialData },
  });

const LinksHandler = res => {
  const socialLinks = [];
  const otherLinks = [];
  const socialLinksTypes = ['instagram', 'facebook', 'whatsapp', 'twitter', 'snapchat', 'tiktok'];
  if (!res) {
    return {};
  }
  res.links.forEach(link => {
    if (socialLinksTypes.includes(link.linkType)) {
      socialLinks.push(link);
    } else {
      otherLinks.push(link);
    }
  });
  const storeLinks = { socialLinks, otherLinks };
  delete res.links;
  return {
    ...res,
    storeLinks,
  };
};

export default useLinks;
